@import "@vmf/components/src/assets/style/_index.scss";




































































































































































































































































































































































































































































.info-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    > .icon {
        flex: 0 0 auto;
        font-size: 26px;
        width: 30px;
        text-align: center;
        margin-right: $margin;
    }
    > .body {
        flex: 1 1 1%;
        text-align: center;
        > .label {
        }
        > .value {
            font-weight: bold;
            font-size: 150%;
            color: $color-primary;
        }
    }
}
